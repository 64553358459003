import type { ParsedUrlQuery } from 'querystring';
import type { UrlObject } from 'url';

import type { LinkProps } from 'next/link';

import { Path, QueryParam, Route } from '../../../enums/route';
import { mergeSsuQueryParams } from '../../../lib/url';
import { ButtonAction } from '../../../types/component';
import type { Button as ButtonType } from '../../../types/content-schema';

interface GetHrefProps {
  query: ParsedUrlQuery;
  action: ButtonType['action'];
  asString?: boolean;
}

const idRouteMap: Record<string, Path> = {
  [Route.MAP]: Path.MAP,
  [Route.FLIGHT_INFO]: Path.FLIGHT_INFO,
  [Route.FAQ]: Path.FAQ,
};

export function getHref({ query, action, asString }: GetHrefProps): string;
export function getHref({ query, action }: GetHrefProps): UrlObject;
export function getHref({ query, action, asString }: GetHrefProps) {
  const { type: actionType, payload } = action;

  let returnValue: LinkProps['href'];

  if (actionType === ButtonAction.LINK_TO_PAGE && payload?.id) {
    const path = idRouteMap[payload?.id];

    if (path) {
      returnValue = {
        pathname: path,
        query: mergeSsuQueryParams(query),
      };
    } else {
      returnValue = {
        pathname: Path.CATCH_ALL,
        query: mergeSsuQueryParams(query, {
          [QueryParam.SSU_PATH]: [payload?.id],
        }),
      };
    }
  } else if (actionType === ButtonAction.LINK_TO_POI && payload?.id) {
    returnValue = {
      pathname: Path.MAP,
      query: mergeSsuQueryParams(query, {
        [QueryParam.POI_ID]: payload?.id,
      }),
    };
  } else if (actionType === ButtonAction.LINK_TO_CATEGORY && payload?.id) {
    returnValue = {
      pathname: Path.MAP,
      query: mergeSsuQueryParams(query, {
        [QueryParam.SSU_PATH]: undefined,
        [QueryParam.CATEGORY]: payload?.id,
        [QueryParam.FROM]: [Route.CONTENT_PAGE],
      }),
    };
  } else {
    return '.';
  }

  if (asString) {
    const { pathname, query } = returnValue;
    const params = new URLSearchParams(query as Record<string, string>);

    return `${pathname}?${params.toString()}`;
  }

  return returnValue;
}
