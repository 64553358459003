import type { Button as ButtonType } from 'pxp-api/clients/strapi/config-type';
import { Route } from '../../../enums/route';
import { NetworkState } from '../../../hooks/use-network-state';

interface IsDisabledProps {
  state: NetworkState;
  action: ButtonType['action'];
}
export const isDisabled = ({ state, action }: IsDisabledProps): boolean => {
  if (
    action?.type === 'LINK_TO_PAGE' &&
    action?.payload?.id !== Route.MAP &&
    action?.payload?.id !== Route.FLIGHT_INFO &&
    action?.payload?.id !== undefined
  ) {
    return false;
  }

  return state === NetworkState.OFFLINE;
};
