import type { HowToList as HowToListType } from 'pxp-api/clients/strapi/config-type';

import { HowToItem } from '../how-to-item/how-to-item';

import css from './how-to-list.module.css';

export interface HowToListProps {
  items: HowToListType['items'];
}

export const HowToList = ({ items }: HowToListProps) => (
  <ul className={css.howToList}>
    {items?.map((item, index) => (
      <HowToItem key={`how-to-item-${index}`} {...item} />
    ))}
  </ul>
);
