import type { ComponentType, FC } from 'react';
import { useMemo } from 'react';

import type { Component } from '../../types/content-schema';
import type { ButtonProps } from '../button/button';
import { Button } from '../button/button';
import type { ButtonGridProps } from '../button-grid/button-grid';
import { ButtonGrid } from '../button-grid/button-grid';
import type { FeedbackBlockProps } from '../feedback/feedback-block';
import { FeedbackBlock } from '../feedback/feedback-block';
import type { FeedbackTranslationsProps } from '../feedback/feedback-translations';
import { FeedbackTranslations } from '../feedback/feedback-translations';
import type { HeadingProps } from '../heading/heading';
import { Heading } from '../heading/heading';
import type { HowToListProps } from '../how-to-list/how-to-list';
import { HowToList } from '../how-to-list/how-to-list';
import type { PageTitleProps } from '../page-title/page-title';
import { PageTitle } from '../page-title/page-title';
import type { QRCodeProps } from '../qr-code/qr-code';
import { QRCode } from '../qr-code/qr-code';
import type { RichTextProps } from '../rich-text/rich-text';
import { RichText } from '../rich-text/rich-text';
import type { SearchBarProps } from '../search-bar/search-bar';
import { SearchBar } from '../search-bar/search-bar';
import type { StaticMapProps } from '../static-map/static-map';
import { StaticMap } from '../static-map/static-map';
import type { TextProps } from '../text/text';
import { Text } from '../text/text';

type ComponentTypes =
  | ComponentType<ButtonGridProps>
  | ComponentType<PageTitleProps>
  | ComponentType<ButtonProps>
  | ComponentType<HeadingProps>
  | ComponentType<FeedbackBlockProps>
  | ComponentType<FeedbackTranslationsProps>
  | ComponentType<TextProps>
  | ComponentType<QRCodeProps>
  | ComponentType<StaticMapProps>
  | ComponentType<RichTextProps>
  | ComponentType<HowToListProps>
  | ComponentType<SearchBarProps>;

const loadableComponents: Record<Component['type'], ComponentTypes> = {
  BUTTON_GRID: ButtonGrid,
  PAGE_TITLE: PageTitle,
  BUTTON: Button,
  FEEDBACK_BUTTONS: FeedbackBlock,
  FEEDBACK_TRANSLATIONS: FeedbackTranslations,
  TEXT: Text,
  HEADING: Heading,
  QR_CODE: QRCode,
  STATIC_MAP: StaticMap,
  RICH_TEXT: RichText,
  HOW_TO_LIST: HowToList,
  SEARCH_BAR: SearchBar,
};

interface RenderComponentsProps {
  components?: Component[];
}

export const RenderComponents: FC<RenderComponentsProps> = ({ components }) => {
  const componentElements = useMemo(
    () =>
      components
        ?.map((rawComponent, index) => {
          const key = `${rawComponent.type}-${index}` as const;
          const Component = loadableComponents[
            rawComponent.type
          ] as ComponentType;

          return Component ? (
            <Component
              key={key}
              {...(typeof rawComponent.properties === 'object'
                ? rawComponent.properties
                : {})}
            />
          ) : null;
        })
        .filter(Boolean),
    [components],
  );

  if (!componentElements?.length) {
    return null;
  }

  return <>{componentElements}</>;
};
