import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { QueryParam } from '@/enums/route';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import type { ContentType } from '@/modules/analytics/types/event-props';
import type { ContentComponentProps } from '@/types/app';

import { FEEDBACK_BLOCK_STATE } from './constants/feedback-block-state';
import css from './feedback-block.module.scss';
import { FeedbackButtons } from './feedback-buttons';
import { State } from './types';
import { useFeedbackState } from './use-feedback-state';

export type FeedbackBlockProps = ContentComponentProps<{
  eventContentType?: ContentType;
  context?: string;
}>;

export const FeedbackBlock: FC<FeedbackBlockProps> = ({
  eventContentType,
  context,
}) => {
  const { state, setStateByKey } = useFeedbackState(FEEDBACK_BLOCK_STATE);
  const { t } = useTranslation();
  const { query } = useRouter();

  const path = query[QueryParam.SSU_PATH] || '';
  const [page] = Array.isArray(path) ? path : [path];

  return (
    <div className={css.root}>
      <h4 className={css.title} {...getTestIdProp('feedback-block-title')}>
        {t(state.title)}
      </h4>
      {state === FEEDBACK_BLOCK_STATE[State.INIT] && (
        <FeedbackButtons
          onSubmit={setStateByKey}
          context={context ? context : page}
          eventContentType={eventContentType}
        />
      )}
    </div>
  );
};
