import type { HowToItem as HowToItemType } from 'pxp-api/clients/strapi/config-type';

import { IconName } from '../../types/icon';
import { Heading } from '../heading/heading';
import { Icon } from '../icon/icon';
import { Text } from '../text/text';

import css from './how-to-item.module.css';

export const HowToItem = ({
  icon,
  translationKeyText,
  translationKeyTitle,
}: HowToItemType) => (
  <li className={css.howToItem}>
    {icon && (
      <Icon className={css.howToItemIcon} name={IconName[icon]} width={40} />
    )}

    <div className={css.howToItemContent}>
      <Heading translationKey={translationKeyTitle} size="SMALL" />
      <Text translationKey={translationKeyText} />
    </div>
  </li>
);
