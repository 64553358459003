import classnames from 'classnames';
import { useTranslation } from 'next-i18next';

import type { ContentComponentProps } from '@/types/app';

import type { Heading as HeadingType } from '../../types/content-schema';

import css from './heading.module.scss';

export type HeadingProps = ContentComponentProps<HeadingType>;

const sizeClassMap = {
  SMALL: css.small,
  MEDIUM: css.medium,
  LARGE: css.large,
};

export const Heading = ({
  className,
  translationKey = '',
  size,
}: HeadingProps) => {
  const classes = classnames(css.root, size && sizeClassMap[size], className);
  const { t } = useTranslation();
  // @ts-expect-error: translationKey is dynamic
  return <h3 className={classes}>{t(translationKey)}</h3>;
};
