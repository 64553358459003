import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { FC, ReactNode } from 'react';

import { getTestIdProp } from '@/lib/get-test-id-prop';
import type { ContentComponentProps } from '@/types/app';

import type { PageTitle as PageTitleType } from '../../types/content-schema';

import css from './page-title.module.scss';

export type PageTitleProps = ContentComponentProps<PageTitleType> & {
  inspiration?: boolean;
  children?: ReactNode;
};

const sizeClassMap = {
  SMALL: css.small,
  MEDIUM: css.medium,
  LARGE: css.large,
};

export const PageTitle: FC<PageTitleProps> = ({
  className,
  translationKeyTitle,
  translationKeySubtitle,
  size,
  inspiration,
  children,
}) => {
  const { t } = useTranslation();
  const classes = classnames(
    css.root,
    size && sizeClassMap[size],
    translationKeySubtitle && css.hasSubtitle,
    'pageTitle',
    className,
  );
  const titleClasses = classnames(css.title, inspiration && css.inspiration);

  return (
    <div className={classes}>
      <>
        {(translationKeyTitle || children) && (
          <h1 className={titleClasses} {...getTestIdProp('page-title')}>
            {children
              ? children
              : translationKeyTitle
              ? // @ts-expect-error: translationKeyTitle is dynamic
                t(translationKeyTitle)
              : ''}
          </h1>
        )}
        {translationKeySubtitle && (
          <h2 className={css.subtitle} {...getTestIdProp('page-subtitle')}>
            {/*
            // @ts-expect-error: translationKeyTitle is dynamic */}
            {t(translationKeySubtitle)}
          </h2>
        )}
      </>
    </div>
  );
};
