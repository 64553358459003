import classNames from 'classnames';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';

import { getTestIdProp } from '@/lib/get-test-id-prop';
import type { ContentComponentProps } from '@/types/app';

import type { StaticMap as StaticMapType } from '../../types/content-schema';
import { Card } from '../card/card';
import { Text } from '../text/text';

import { Legend } from './legend';
import css from './static-map.module.scss';

export type StaticMapProps = ContentComponentProps<StaticMapType>;

export const StaticMap = ({
  translationKeyTitle,
  translationKeyText,
  legend,
  image,
  imageAlt,
}: StaticMapProps) => {
  const { t } = useTranslation();
  const imageAltText: string = t(imageAlt, { defaultValue: 'Image of a map' });

  return (
    <div className={css.root}>
      {image?.url && (
        <div className={css.imageContainer}>
          <Image
            src={image.url}
            alt={imageAltText}
            layout="fill"
            {...getTestIdProp('static-map-image')}
          />
        </div>
      )}
      <div className={css.floatingContainer}>
        <Card {...getTestIdProp('static-map-legend')}>
          <h1 className={classNames(css.title, css.withBorder)}>
            {/*
            // @ts-expect-error: translationKeyTitle is dynamic */}
            {t(translationKeyTitle)}
          </h1>
          {legend && legend.length > 0 && (
            <ul className={css.withBorder}>
              {legend.map((legendItem) => (
                <Legend key={legendItem.icon} {...legendItem} />
              ))}
            </ul>
          )}
          <Text className={css.text} translationKey={translationKeyText} />
        </Card>
      </div>
    </div>
  );
};
