import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { useTranslation } from 'next-i18next';
import { getTestIdProp } from '../../lib/get-test-id-prop';
import { QueryParam } from '../../enums/route';
import { useDataLayer } from '../../modules/analytics/data-layer-provider';
import { UIEventName } from '../../modules/analytics/events';
import type { Feedback } from '../../modules/analytics/types/event-props';
import { IconName } from '../../types/icon';
import { FEEDBACK_TRANSLATIONS_STATE } from './constants/feedback-translations-state';
import type { FeedbackRatingOption } from './feedback-rating';
import { FeedbackRating } from './feedback-rating';
import css from './feedback-translations.module.scss';
import { State } from './types';
import { useFeedbackState } from './use-feedback-state';

const ONCLICK_DEBOUNCE_TIMEOUT = 3000;
const RATING_OPTIONS_AMOUNT = 5;

export interface FeedbackTranslationsProps {
  ratingOptionsAmount?: number;
  className?: string;
  locales: string[];
  enabled: boolean;
}

export const FeedbackTranslations: FC<FeedbackTranslationsProps> = ({
  className,
  locales,
  enabled,
  ratingOptionsAmount = RATING_OPTIONS_AMOUNT,
}) => {
  const classNames = classnames(css.root, className);
  const { t, i18n } = useTranslation();
  const { state, setStateByKey } = useFeedbackState(
    FEEDBACK_TRANSLATIONS_STATE,
  );
  const dataLayer = useDataLayer();
  const { query } = useRouter();
  const path = query[QueryParam.SSU_PATH] || '';
  const [slug] = Array.isArray(path) ? path : [path];
  const showRatingIcons = state === FEEDBACK_TRANSLATIONS_STATE[State.INIT];
  const ratingOptions = useMemo(
    () =>
      [...(new Array(ratingOptionsAmount) as number[])].map(
        (_, ratingOption) => ({
          value: ratingOption + 1,
          icon: IconName.STAR,
        }),
      ),
    [ratingOptionsAmount],
  );

  const onClick = (ratingOption: number) => {
    setStateByKey(State.SUCCESS);

    dataLayer.publish<Feedback>(UIEventName.FEEDBACK_TRANSLATIONS_SUBMIT, {
      value: ratingOption,
      slug,
    });
  };

  const debouncedOnClick = useRef(debounce(onClick, ONCLICK_DEBOUNCE_TIMEOUT));

  const onSelect = (option: FeedbackRatingOption) => {
    debouncedOnClick.current(option.value);
  };

  if (!enabled || !locales.includes(i18n.language)) {
    return null;
  }

  return (
    <div className={classNames}>
      <h4
        className={css.title}
        {...getTestIdProp('feedback-translations-title')}
      >
        {t(state.title)}
      </h4>
      {showRatingIcons && (
        <FeedbackRating options={ratingOptions} onSelect={onSelect} />
      )}
    </div>
  );
};
