import type { UseFeedbackStates } from '@/components/feedback/use-feedback-state';

import { State } from '../types';

export const FEEDBACK_TRANSLATIONS_STATE = {
  [State.INIT]: {
    title: 'general.feedback.translations.title.initial',
  },
  [State.SUCCESS]: {
    title: 'general.feedback.translations.title.success',
  },
} satisfies UseFeedbackStates;
