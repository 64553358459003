import type { FC } from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import type { IconName } from '../../types/icon';
import { Icon } from '../icon/icon';
import css from './feedback-rating.module.scss';

export interface FeedbackRatingOption {
  value: number;
  label?: string;
  icon?: IconName;
}

interface FeedbackRating {
  onSelect(rating: FeedbackRatingOption): void;
  options: FeedbackRatingOption[];
}

export const FeedbackRating: FC<FeedbackRating> = ({ options, onSelect }) => {
  const [rating, setRating] = useState(0);
  const { t } = useTranslation();

  const onClick = (option: FeedbackRatingOption, index: number) => () => {
    setRating(index + 1);
    onSelect(option);
  };

  return (
    <div className={css.root}>
      <ul className={css.options}>
        {options.map((option, index) => (
          <li
            key={option.value}
            className={classnames(css.option, index < rating && css.active)}
          >
            <button className={css.button} onClick={onClick(option, index)}>
              {option.icon && <Icon name={option.icon} className={css.icon} />}
              {option.label}
            </button>
          </li>
        ))}
      </ul>
      <div className={css.ratingDescription}>
        <span className={css.ratingLabel}>
          {t('general.feedback.bad', { defaultValue: 'Very bad' })}
        </span>
        <span className={css.ratingLabel}>
          {t('general.feedback.perfect', { defaultValue: 'Perfect' })}
        </span>
      </div>
    </div>
  );
};
