import { useTranslation } from 'next-i18next';

import type { ContentComponentProps } from '@/types/app';
import { IconName } from '@/types/icon';

import type { Legend as LegendType } from '../../types/content-schema';
import { Icon } from '../icon/icon';

import css from './static-map.module.scss';

export type LegendProps = ContentComponentProps<LegendType[number]>;

export const Legend = ({ icon, translationKey }: LegendProps) => {
  const { t } = useTranslation();

  return (
    <li className={css.legend}>
      {icon && (
        <Icon
          className={css.legendIcon}
          name={icon && (IconName[icon] as IconName)}
        />
      )}
      {/*
      // @ts-expect-error: translationKey is dynamic */}
      {translationKey && <span>{t(translationKey)}</span>}
    </li>
  );
};
