import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';

import { useModal } from '@/context/modal-provider';
import { Module } from '@/enums/module';
import { Path } from '@/enums/route';
import { useDefaultLocaleTranslation } from '@/hooks/use-default-locale-translation';
import { useIsModuleEnabled } from '@/hooks/use-is-module-enabled';
import { useNetworkState } from '@/hooks/use-network-state';
import { Modal } from '@/modals';
import { useDataLayer } from '@/modules/analytics/data-layer-provider';
import { UIEventName } from '@/modules/analytics/events';
import type { ButtonClick } from '@/modules/analytics/types/event-props';
import type { Modal as OldModal } from '@/store/modalStore';
import { useModalStore } from '@/store/modalStore';
import type { ContentComponentProps } from '@/types/app';
import { ButtonAction } from '@/types/component';
import { IconName } from '@/types/icon';

import type { Button as ButtonType } from '../../types/content-schema';
import { ButtonBare } from '../button-bare/button';

import { isDisabled } from './helpers/disabled';
import { getHref } from './helpers/href';

export type ButtonProps = ContentComponentProps<ButtonType>;

export const Button = ({
  action,
  className,
  icon,
  image,
  size,
  translationKey,
  type: variant,
}: ButtonProps) => {
  const { type: actionType, payload } = action;
  const buttonRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null);
  const { t } = useTranslation();
  const fixedT = useDefaultLocaleTranslation();
  const dataLayer = useDataLayer();
  const classes = classnames('button', className);
  const { query, push } = useRouter();
  const { state } = useNetworkState();
  const disabled = isDisabled({ state, action });
  const actionIsVideoCall = actionType === ButtonAction.START_VIDEO_CALL;
  const actionIsOpenModal = actionType === ButtonAction.OPEN_MODAL;
  const isMapModuleEnabled = useIsModuleEnabled(Module.MAP);

  const oldOpenModal = useModalStore((state) => state.openModal);
  const { openModal } = useModal();

  if (!isMapModuleEnabled && actionType === ButtonAction.LINK_TO_POI) {
    return null;
  }

  function onClick() {
    const buttonClickPayload: ButtonClick = {
      contentType: actionIsOpenModal
        ? 'modal'
        : actionIsVideoCall
          ? 'video_call'
          : 'content',
      // @ts-expect-error - key can be dynamic
      value: fixedT(translationKey),
      target: actionIsOpenModal ? payload?.id : payload?.meta?.target,
    };

    dataLayer.publish<ButtonClick>(
      UIEventName.BUTTON_CLICK,
      buttonClickPayload,
    );

    if (actionIsVideoCall) {
      push({ pathname: Path.CALL_AGENT, query });
    }

    if (actionIsOpenModal && payload?.id) {
      // use the new modal system for supported modals
      // TODO: Remove once all modals have been converted
      if (Object.keys(Modal).includes(payload.id)) {
        openModal(payload.id as Modal, {}, buttonRef.current);
      } else {
        oldOpenModal(payload.id as OldModal);
      }
    }
  }

  const button = (as: 'a' | 'button') => (
    <ButtonBare
      as={as}
      ref={buttonRef}
      className={classes}
      disabled={disabled}
      href={as === 'a' ? getHref({ query, action, asString: true }) : undefined}
      icon={icon && IconName[icon]}
      image={image}
      // @ts-expect-error - we can expect dynamic keys here
      label={t(translationKey)}
      onClick={!disabled ? onClick : undefined}
      size={size}
      testId={payload?.id}
      variant={variant}
    />
  );

  return (
    <>
      {actionType && !(actionIsVideoCall || actionIsOpenModal)
        ? button('a')
        : button('button')}
    </>
  );
};
