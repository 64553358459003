import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Path } from '@/enums/route';
import { mergeSsuQueryParams } from '@/lib/url';
import { IconName } from '@/types/icon';

import { Icon } from '../icon/icon';

import css from './search-bar.module.scss';

export interface SearchBarProps {
  placeholder: string;
}
export const SearchBar = ({ placeholder }: SearchBarProps) => {
  const { t } = useTranslation();
  const { query } = useRouter();

  const href = {
    pathname: Path.SEARCH,
    query: mergeSsuQueryParams(query, {
      ...query,
    }),
  };

  // @ts-expect-error: translationKey is dynamic
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- valid translation interpolation
  const placeholderText: string = t(placeholder);

  return (
    <Link href={href} className={css.root}>
      <Icon name={IconName.SEARCH} className={css.icon} />
      {placeholderText}
    </Link>
  );
};
