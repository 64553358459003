import classnames from 'classnames';

import type { ContentComponentProps } from '../../types/app';
import type { ButtonGrid as ButtonGridType } from '../../types/content-schema';
import { Button } from '../button/button';

import css from './button-grid.module.scss';

export type ButtonGridProps = ContentComponentProps<ButtonGridType>;

export const ButtonGrid = ({
  columns,
  buttons,
  className,
}: ButtonGridProps) => {
  const classes = classnames(
    css.root,
    columns && css[`columns${columns}`],
    className,
  );

  if (!buttons?.length) return null;

  return (
    <div className={classes}>
      {buttons.map((button) => (
        <Button key={JSON.stringify(button.action)} {...button} />
      ))}
    </div>
  );
};
