import type { FC } from 'react';
import classnames from 'classnames';
import type { RendererObject } from 'marked';
import { marked } from 'marked';
import { setInnerHtml } from '../../lib/setInnerHtml';
import type { ContentComponentProps } from '../../types/app';
import type { RichText as RichTextType } from '../../types/content-schema';
import css from './text.module.scss';

const renderer = {
  // Should render headings as paragraphs
  heading(text: string) {
    return `<p><strong>${text}</strong></p>`;
  },
  // Should not render links
  link(_href: string, _title: string, text: string) {
    return text;
  },
} as RendererObject;

marked.use({ renderer });

export type RichTextProps = ContentComponentProps<RichTextType>;

export const RichText: FC<RichTextProps> = ({ className, text = '' }) => {
  const classes = classnames(css.root, className, 'text');
  const html = marked.parse(text) as unknown as string;

  return <div className={classes} {...setInnerHtml(html)} />;
};
