import classnames from 'classnames';
import { QRCodeSVG } from 'qrcode.react';
import type { FC } from 'react';

import { useSSU } from '@/context/ssu-provider';
import { getTestIdProp } from '@/lib/get-test-id-prop';
import type { ContentComponentProps } from '@/types/app';
import type { QRCode as QRCodeType } from '@/types/content-schema';

import css from './qr-code.module.scss';

export type QRCodeProps = ContentComponentProps<QRCodeType>;

export const QRCode: FC<QRCodeProps> = ({ value, className }) => {
  const classNames = classnames(css.root, 'qrCode', className);
  const {
    theme: { color },
  } = useSSU();

  const fgColor = color.qrCode || '#000000';

  return (
    <QRCodeSVG
      className={classNames}
      value={value}
      fgColor={fgColor}
      level="L"
      {...getTestIdProp('qrcode')}
    />
  );
};
